import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const InfoBox = ({ children, title, style, icon, smallIcon, iconAltText }) => {
  const getSmallIconContent = () => (
    <div className={styles.contentSmallIcon}>
      <div className={styles.titleContainerSmallIcon}>
        {icon && <img src={icon} alt={iconAltText || ""} />}
        {title && <H>{title}</H>}
      </div>
      {children}
    </div>
  )

  const getLargeIconContent = () => (
    <div className={styles.contentLargeIcon}>
      {icon && <img src={icon} alt={iconAltText || ""} />}
      <div className={styles.textLargeIcon}>
        {title && <H>{title}</H>}
        {children}
      </div>
    </div>
  )

  return (
    <div style={style} className={styles.container}>
      {smallIcon ? getSmallIconContent() : getLargeIconContent()}
    </div>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  icon: PropTypes.string,
  smallIcon: PropTypes.bool,
  iconAltText: PropTypes.string,
}

InfoBox.defaultProps = {
  title: null,
  style: null,
  icon: null,
  smallIcon: false,
  iconAltText: null,
  children: null,
}

export default InfoBox
