import classNames from "classnames"
import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"

const LinkWrapper = ({ children, className }) => (
  <nav
    className={classNames(
      "flex",
      "flex-space-between",
      "mobile-flex-column",
      "mobile-flex-gap",
      "desktop-col-12",
      styles.linkWrapperPadding,
      className
    )}
  >
    {children}
  </nav>
)

LinkWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
}

LinkWrapper.defaultProps = {
  className: "",
}

export default LinkWrapper
