import React from "react"
// import { Button } from "."
// import { clearLocalStorage } from "../state/localStorage"

// Disabled for user testing
const VersionNumber = () => {
  // const envName = process.env.GATSBY_INSTANCE_NAME

  return <></>

  // if (envName === "production" || envName === "develop") return <></>
  // if (!process.env.GATSBY_BUILD_NUMBER) {
  //   return <></>
  // }
  // return (
  //   <div style={{ opacity: "0.5" }}>
  //     <h5>For utviklere:</h5>
  //     <p>
  //       {`Last deployed: ${process.env.GATSBY_BUILD_NUMBER.replace(/_/g, " ")}`}
  //       <br />
  //       {`Commit: ${process.env.GATSBY_GIT_SHA}`}
  //       <br />
  //       <br />
  //       <Button.Secondary
  //         onClick={() => {
  //           clearLocalStorage()
  //           window.location.reload()
  //         }}
  //       >
  //         Slett local storage
  //       </Button.Secondary>
  //     </p>
  //   </div>
  // )
}

export default VersionNumber
