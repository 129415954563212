import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from ".."

const BackLink = ({ to, children, className }) => {
  return (
    <div className={classNames(className)}>
      <Link.Big url={to} arrow="left" secondary>
        {children !== null ? children : "Til forside"}
      </Link.Big>
    </div>
  )
}

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string,
  className: PropTypes.string,
}

BackLink.defaultProps = {
  children: null,
  className: null,
}

export default BackLink
