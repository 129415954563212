import React from "react"
import classNames from "classnames"
import { ButtonType, ButtonTypeDefaults } from "../../propTypes"
import styles from "./style.module.scss"
import icons from "./_icons"
import Button from "."

const getIconFromType = type => {
  const lowercased = type.toLowerCase()
  switch (lowercased) {
    case "veggpryd":
      return { icon: icons.veggpryd, alt: "Illustrasjon som viser omriss av veggpryd" }
    case "tabell":
      return { icon: icons.tabell, alt: "Illustrasjon som viser omriss av tabell" }
    case "utskriftsvennlig":
      return { icon: icons.utskrift, alt: "Illustrasjon som viser omriss av dokument" }
    default:
      return { icon: icons.utskrift, alt: "Illustrasjon som viser omriss av dokument" }
  }
}

const Export = ({ id, className, icon, disabled, type, onClick, children }) => {
  const classes = classNames(styles.export, className, {
    [styles.disabled]: disabled,
  })

  const illustation = getIconFromType(type)

  return (
    <div
      id={id}
      role="button"
      className={classes}
      tabIndex={-1}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === "Enter") {
          onClick()
        }
      }}
    >
      <div className={styles.exportIllustration}>
        <img src={illustation.icon} alt={illustation.alt} />
      </div>
      <Button.Primary icon={icon}>{children}</Button.Primary>
    </div>
  )
}

Export.propTypes = ButtonType
Export.defaultProps = ButtonTypeDefaults

export default Export
