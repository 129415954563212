import { omit } from "lodash"
import {
  ADD_BEVARINGSPUNKT,
  DELETE_BEVARINGSPUNKT,
  CHECK_BEVARINGSPUNKT,
  UPDATE_BEVARINGSPUNKT_TITLE,
  SET_DIRTY_BEVARINGSPUNKT_TITLE,
  CANCEL_EDIT_BEVARINGSPUNKT_TITLE,
} from "./actions"
import { createUuid } from "../../utils"

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_BEVARINGSPUNKT: {
      const { industryId, subjectId } = action

      const id = createUuid()
      const newBevaringspunkt = {
        id,
        industryId,
        subjectId,
        title: { text: "", backupText: "" },
        isChecked: true,
        isNew: true,
      }

      return {
        ...state,
        [id]: newBevaringspunkt,
      }
    }
    case DELETE_BEVARINGSPUNKT: {
      const { bevaringspunktId } = action

      return omit(state, bevaringspunktId)
    }
    case CHECK_BEVARINGSPUNKT: {
      const { bevaringspunktId } = action
      const { isChecked } = state[bevaringspunktId]

      return {
        ...state,
        [bevaringspunktId]: {
          ...state[bevaringspunktId],
          isChecked: !isChecked,
        },
      }
    }
    case UPDATE_BEVARINGSPUNKT_TITLE: {
      const { bevaringspunktId, title } = action

      const bevaringspunkt = state[bevaringspunktId]

      return {
        ...state,
        [bevaringspunktId]: {
          ...state[bevaringspunktId],
          title: {
            ...state[bevaringspunktId].title,
            text: typeof title === "undefined" ? bevaringspunkt.title.text : title,
          },
          isNew: false,
        },
      }
    }
    case SET_DIRTY_BEVARINGSPUNKT_TITLE: {
      const { bevaringspunktId, isDirty } = action
      // If dirty; take a backup of the real text into backupText, so we can use it later if the user cancels editing
      const titleValue = state[bevaringspunktId].title
      const updatedTitle = {
        ...titleValue,
        backupText: isDirty ? titleValue.text : titleValue.backupText,
      }

      return {
        ...state,
        [bevaringspunktId]: {
          ...state[bevaringspunktId],
          title: updatedTitle,
        },
      }
    }
    case CANCEL_EDIT_BEVARINGSPUNKT_TITLE: {
      const { bevaringspunktId } = action

      // If cancel is called, the text should be replaced with backupText
      const titleValue = state[bevaringspunktId].title
      const updatedTitle = {
        ...titleValue,
        text: titleValue.backupText,
        backupText: "",
      }

      return {
        ...state,
        [bevaringspunktId]: {
          ...state[bevaringspunktId],
          title: updatedTitle,
        },
      }
    }
    default:
      return state
  }
}
