/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { BlockContent } from ".."
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const Hero = ({ title, content, image, altImageText, imageRightSide, portableContent }) => {
  const getImage = () => {
    return (
      <>
        <div className="desktop-col-5 center-content mobile-display-none tablet-display-none">
          <img src={image} alt={altImageText} />
        </div>
      </>
    )
  }
  return (
    <div className="content">
      {imageRightSide ? null : getImage()}
      <div className="desktop-col-7">
        <H className={classNames(styles.title, "h1")}>{title}</H>
        <img src={image} alt={altImageText} className="desktop-display-none" />
        {content && portableContent && (
          <div className={styles.content}>
            <BlockContent blocks={content || []} />
          </div>
        )}
        {content && !portableContent && (
          <div
            className={(styles.content, "markdown")}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
      {imageRightSide ? getImage() : null}
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any,
  image: PropTypes.string.isRequired,
  altImageText: PropTypes.string.isRequired,
  portableContent: PropTypes.bool,
  imageRightSide: PropTypes.bool,
}

Hero.defaultProps = {
  content: null,
  imageRightSide: false,
  portableContent: false,
}

export default Hero
