import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const Subject = ({ title, smallTitle, subject }) => {
  return (
    <>
      <div className={styles.container}>
        <div>
          {smallTitle && (
            <H className="h2 normal-font-weight" style={{ margin: 0 }}>
              {smallTitle}
            </H>
          )}
          <h1 className="h1 normal-font-weight" style={{ margin: 0 }}>
            {title} {subject ? subject.title : ""}
          </h1>
        </div>
      </div>
    </>
  )
}

Subject.propTypes = {
  title: PropTypes.string.isRequired,
  smallTitle: PropTypes.string,
  subject: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
}

Subject.defaultProps = {
  smallTitle: null,
  subject: null,
}

export default Subject
