import classNames from "classnames"
import { ButtonType, ButtonTypeDefaults } from "../../propTypes"
import styles from "./style.module.scss"
import createButton from "./_buttonFactory"
import withDisabledCheck from "../../hooks/withDisabledCheck"

const Secondary = ({ id, style, className, icon, disabled, type, onClick, url, children }) => {
  const classes = classNames(styles.secondaryButton, className, {
    [styles.disabled]: disabled,
  })

  return createButton(id, style, classes, icon, disabled, type, onClick, url, children)
}

Secondary.propTypes = ButtonType
Secondary.defaultProps = ButtonTypeDefaults

export default withDisabledCheck(Secondary)
