/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Button } from ".."
import styles from "./style.module.scss"

const usePopup = (isOpenDefault = false, onClose = () => {}, canClose = true) => {
  const [isOpen, setPopupOpen] = useState(isOpenDefault)

  const escFunction = useCallback(event => {
    if (event.key.toLowerCase() === "escape") {
      setPopupOpen(false)
    }
  }, [])

  useEffect(() => {
    if (!window) return null
    window.document.addEventListener("keydown", escFunction, false)

    return () => {
      if (!window) return
      window.document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const Modal = ({ children }) => {
    if (!isOpen) {
      return null
    }

    return (
      <div
        className={styles.overlay}
        onClick={() => {
          if (canClose) {
            setPopupOpen(false)
          }
        }}
      >
        <div className={styles.centerContainer}>
          <div className={styles.container} onClick={e => e.stopPropagation()}>
            {canClose && (
              <div className={styles.closeBtnContainer}>
                <Button.Tertiary type="button" icon="cross" onClick={() => setPopupOpen(false)}>
                  Avbryt og lukk
                </Button.Tertiary>
              </div>
            )}
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    )
  }

  Modal.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any.isRequired,
  }

  return [Modal, setPopupOpen]
}

export default usePopup
