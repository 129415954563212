import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { IndustryType, SubjectType } from "../../propTypes"
import MobileSecondaryMenu from "./mobileMenu"
import DefaultSecondaryMenu from "./defaultMenu"
import useSanity from "../../hooks/useSanity"
import { withDefaults } from "../../utils"

const SecondaryMenu = ({ industry, subject, subjects, desktopClassName }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "hjelpen_snarveimeny",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          tag
        },
      }`,
    params
  )

  if (loading || error) return null

  const menuData = withDefaults(data, industry.tag)

  return (
    <>
      <DefaultSecondaryMenu
        industry={industry}
        subject={subject}
        subjects={subjects}
        className={classNames(desktopClassName, "mobile-display-none", "tablet-display-none")}
        texts={menuData}
      />
      <MobileSecondaryMenu
        industry={industry}
        subject={subject}
        subjects={subjects}
        className={classNames("desktop-display-none")}
        texts={menuData}
      />
    </>
  )
}

SecondaryMenu.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  desktopClassName: PropTypes.string,
}

SecondaryMenu.defaultProps = {
  subject: null,
  desktopClassName: null,
}

export default SecondaryMenu
