import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import useScrollPosition from "../../hooks/useScrollPosition"
import styles from "./style.module.scss"
import ArrowUp from "../../images/icons/arrow-up.svg"

const ToTheTopButton = ({ text }) => {
  const [btnStyle, setBtnStyle] = useState(styles.hide)

  useScrollPosition(
    ({ currPos }) => {
      setBtnStyle(currPos.y > 1400 ? styles.show : styles.hide)
    },
    null,
    true
  )

  const toTheTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div className={classNames(styles.container, btnStyle)}>
      <button type="button" className={styles.btn} onClick={toTheTop}>
        <div aria-hidden className={styles.imageContainer}>
          <img src={ArrowUp} alt="" />
        </div>
        <span>{text}</span>
      </button>
    </div>
  )
}

ToTheTopButton.propTypes = {
  text: PropTypes.string,
}

ToTheTopButton.defaultProps = {
  text: "Til toppen",
}

export default ToTheTopButton
