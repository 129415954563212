import React from "react"
import PropTypes from "prop-types"
import { H } from "../state/levelContext"

const ChapterWrapping = ({ title, headerTag, children }) => {
  return (
    <div className="" style={{ justifyContent: "space-between" }}>
      {title ? (
        <div>
          <H className={headerTag}>{title}</H>
        </div>
      ) : null}
      <div>{children}</div>
    </div>
  )
}

ChapterWrapping.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  headerTag: PropTypes.string,
}

ChapterWrapping.defaultProps = {
  headerTag: "h3",
  title: null,
}

export default ChapterWrapping
