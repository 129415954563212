/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import styles from "./style.module.scss"
import { IndustryType, SubjectType } from "../../propTypes"

// TODO: Configure labels from Sanity
const Progress = ({ activeStep, industry, subject, labels }) => {
  const steps = [
    {
      label: labels.stepOneLabel || "",
      active: activeStep === 1,
      completed: activeStep > 0,
      link: `/bransje/${industry.route}/${subject.route}`,
    },
    {
      label: labels.stepTwoLabel || "",
      active: activeStep === 2,
      completed: activeStep > 1,
      link: `/bransje/${industry.route}/${subject.route}/forbedringspunkter`,
    },
    {
      label: labels.stepThreeLabel || "",
      active: activeStep === 3,
      completed: activeStep > 2,
      link: `/bransje/${industry.route}/${subject.route}/handlingsplan`,
    },
  ]

  // render the progress bar
  return (
    <div style={{ width: "100%" }}>
      <div className={`${styles.progressBarWrapper}`}>
        <ul className={`${styles.stepProgressBar}`}>
          {steps.map((step, i) => {
            return (
              <li
                key={`step-${step.label}`}
                className={`${styles.progressStep}${step.completed ? ` ${styles.completed}` : ""}${
                  step.active ? ` ${styles.current}` : ""
                }`}
              >
                <GatsbyLink key={step.label} aria-label={step.label} to={step.link}>
                  {!step.active && (
                    <span
                      className={`${styles.stepIndex}${
                        step.completed ? ` ${styles.completed}` : ""
                      }`}
                    >
                      <span>{i + 1}</span>
                    </span>
                  )}
                  {step.active && (
                    <span className={styles.stepIndex}>
                      <span className={styles.innerWrapper}>{i + 1}</span>
                    </span>
                  )}
                  <div className={`${styles.stepLabel} ${step.active ? ` ${styles.current}` : ""}`}>
                    {step.label}
                  </div>
                </GatsbyLink>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

Progress.propTypes = {
  activeStep: PropTypes.number.isRequired,
  industry: IndustryType.isRequired,
  subject: SubjectType.isRequired,
  labels: PropTypes.any.isRequired,
}

export default Progress
