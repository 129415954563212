import classNames from "classnames"
import { ButtonType, ButtonTypeDefaults } from "../../propTypes"
import styles from "./style.module.scss"
import createButton from "./_buttonFactory"
import withDisabledCheck from "../../hooks/withDisabledCheck"

const Primary = ({ id, style, className, icon, disabled, type, onClick, url, children }) => {
  const classes = classNames(styles.primaryButton, className, {
    [styles.disabled]: disabled,
  })

  return createButton(id, style, classes, icon, disabled, type, onClick, url, children)
}

Primary.propTypes = ButtonType
Primary.defaultProps = ButtonTypeDefaults

export default withDisabledCheck(Primary)
