import React from "react"
import PropTypes from "prop-types"
import { Chapter, Accordion } from "."

const ChapterAccordion = ({ chapters, subchapter, OfflineModule, expand }) => {
  return chapters.map(chapter => {
    return (
      <Accordion.CustomContent
        style={{ marginBottom: "3rem" }}
        id={chapter._key}
        key={chapter._key}
        title={chapter.title}
        isVisited
        defaultOpen={expand}
        headerBackgroundColor={subchapter ? "lightBlue" : "darkGrey"}
        backgroundColor={subchapter ? "lightBlue" : "darkGrey"}
        headerBorderColor={subchapter ? "lightBlue" : "darkGrey"}
      >
        <Chapter
          headerTag="h2"
          description={chapter.description}
          content={chapter._type === "offlineModule" ? [] : chapter.content}
          Module={chapter._type === "offlineModule" ? OfflineModule : null}
          key={chapter._key}
          subchapters={chapter.subchapter}
        />
      </Accordion.CustomContent>
    )
  })
}

ChapterAccordion.propTypes = {
  subchapter: PropTypes.bool,
  expand: PropTypes.bool,
}

ChapterAccordion.defaultProps = {
  subchapter: false,
  expand: false,
}

export default ChapterAccordion
