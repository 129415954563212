import PropTypes from "prop-types"

const MenuTextsProptype = PropTypes.shape({
  menuItem1: PropTypes.string,
  menuItem2: PropTypes.string,
  menuItem3: PropTypes.string,
  menuItem4: PropTypes.string,
  menuItem5: PropTypes.string,
  subMenuItem1: PropTypes.string,
  subMenuItem2: PropTypes.string,
  guideSubMenuItem1: PropTypes.string,
  guideSubMenuItem2: PropTypes.string,
  mobileOpenMenuBtnText: PropTypes.string,
})

// eslint-disable-next-line import/prefer-default-export
export { MenuTextsProptype }
