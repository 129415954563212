import React, { createContext } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import useSanity from "../hooks/useSanity"
import { Loading } from "../components"

export const CodelistCtx = createContext({})

export const CodelistProvider = ({ children, industryRoute, subjectRoute }) => {
  const shouldIncludeWIP = ["develop", "preview"].includes(process.env.GATSBY_ENV)
    ? ""
    : "&& isActive==true"

  const { loading, error, data } = useSanity(
    `{
      "subjects": *[_type=="subject" &&
    count(*[_id=="drafts."+^._id])==0] {
       ...,
      image {
       ...,
        asset-> {
          url
        }
      }
    },
   "industries": *[_type=="industry" &&
    count(*[_id=="drafts."+^._id])==0 && _id!="default" ${shouldIncludeWIP}] {
       ...,
      heroImage {
       ...,
        asset-> {
          url
        }
      },
      "tools": coalesce(tools, []),
      "skipPages": coalesce(skipPages, []),
    }
   }`
  )
  const loc = useLocation()

  if (loading || error) return <Loading error={error} hidden />

  const querystringIndustryTagValue = queryString.parse(loc.search).bransje

  const { industries, subjects } = data
  const industry =
    industries.find(i => i.route === industryRoute) ||
    industries.find(i => i.tag === querystringIndustryTagValue)

  const subject = subjects.find(s => s.route === subjectRoute)
  return (
    <CodelistCtx.Provider
      value={{
        industry,
        industries,
        subjects,
        subjectCount: subjects.length,
        subject,
      }}
    >
      {children}
    </CodelistCtx.Provider>
  )
}

CodelistProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  industryRoute: PropTypes.string,
  subjectRoute: PropTypes.string,
}

CodelistProvider.defaultProps = {
  industryRoute: null,
  subjectRoute: null,
}
