import React from "react"
import classNames from "classnames"
import { Link, ToTheTopButton } from ".."
import { GetFrontPageOmLinks } from "../../constants"
import LogoAt from "../../images/logo/at_logo.svg"
import styles from "./style.module.scss"
import { Section } from "../../state/levelContext"
import VersionNumber from "../versionNumber"

const FooterFrontpage = () => {
  const frontpageLinks = GetFrontPageOmLinks()
  return (
    <footer className={classNames(styles.background)}>
      <Section>
        <div className="content">
          <div className={classNames("desktop-col-12", styles.footerContent)}>
            <a href="https://www.arbeidstilsynet.no/" target="_blank" rel="noopener external">
              <img src={LogoAt} alt="Arbeidstilsynet" className={styles.logo} />
            </a>

            <Section>
              {frontpageLinks.items.map(item => (
                <div key={`footer-link-${item.title}`} style={{ lineHeight: "70px" }}>
                  {item.link.startsWith("https") ? (
                    <Link.Blank url={item.link}>{item.title}</Link.Blank>
                  ) : (
                    <Link.Default url={item.link}>{item.title}</Link.Default>
                  )}
                </div>
              ))}
            </Section>

            <VersionNumber />
          </div>
        </div>
      </Section>
      <ToTheTopButton />
    </footer>
  )
}

export default FooterFrontpage
