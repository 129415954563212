// import { Hjelpen, Portal, Personvern, Om, Kontakt, NotFound, Index } from "./templates"

/*
  const { Hjelpen, Portal, Personvern, Om, Kontakt } = await import("./templates")
  const NotFound = await import("./pages/404")
  const Index = await import("./templates/main")

*/

const setParent = (children, parent = null) => {
  children.forEach(r => {
    // eslint-disable-next-line no-param-reassign
    r.parent = parent ? { fullPath: parent.fullPath, title: parent.title } : null
    // eslint-disable-next-line no-param-reassign
    r.fullPath = parent ? parent.fullPath + r.path : r.path
    if (r.children) setParent(r.children, r)
  })

  return children
}

export const SITE_ROUTES = [
  { path: "/", component: "Index", ignoreInBreadcrumb: true, title: "Forside" },
  {
    path: "/bransje/:industryRoute",
    component: "Hjelpen.Main",
    ignoreInBreadcrumb: true,
    title: "Hjem",
    children: [
      {
        path: "/om",
        component: "Hjelpen.Om",
        title: "Slik bruker dere arbeidsmiljøhjelpen",
      },
      {
        path: "/utvidet-veileder",
        component: "Hjelpen.UtvidetVeileder",
        title: "Utvidet veileder",
      },
      {
        path: "/moteleder",
        component: "Hjelpen.Moteleder",
        title: "For deg som skal lede møtet",
      },
      {
        path: "/temavelger",
        component: "Hjelpen.Temavelger",
        title: "Velg tema",
      },
      {
        path: "/veienvidere",
        component: "Hjelpen.Veienvidere",
        title: "Etter at planen er utfylt",
      },
      {
        path: "/bevaringspunkter",
        component: "Hjelpen.Bevaringspunkter",
        title: "Bevaringspunkter",
      },
      {
        path: "/:subjectRoute",
        component: "Hjelpen.Intro",
        title: "Intro",
      },
      {
        path: "/:subjectRoute/forbedringspunkter",
        component: "Hjelpen.Forbedringspunkter",
        title: "Forbedringspunkter",
      },
      {
        path: "/:subjectRoute/handlingsplan",
        component: "Hjelpen.Handlingsplan",
        title: "Handlingsplan",
      },
      {
        path: "/handlingsplan/sammendrag",
        component: "Hjelpen.Sammendrag",
        title: "Sammendrag",
      },
    ],
  },
  {
    path: "/arbeidsmiljohjelpen",
    component: "Hjelpen.LandingssideMedVelger",
    title: "Arbeidsmiljøhjelpen",
  },
  { path: "/personvern", component: "Personvern", title: "Personvern" },
  { path: "/kontakt", component: "Kontakt", title: "Kontakt" },
  { path: "/om", component: "Hjelpen.Om", title: "Slik bruker dere arbeidsmiljøhjelpen" },
  {
    path: "/vanlige-sporsmal",
    component: "Vanligesporsmal",
    title: "Vanlige spørsmål",
  },
  { path: "*", component: "NotFound", title: "404 - Siden finnes ikke" },
]

export const FLAT_SITE_ROUTES = {}
const flatten = childen => {
  childen.forEach(r => {
    FLAT_SITE_ROUTES[r.fullPath] = r
    if (r.children) flatten(r.children)
  })
}
flatten(setParent(SITE_ROUTES))

export const populateRoute = (route, industry, subject) => {
  let newRoute = route
  newRoute = industry ? newRoute.replace(":industryRoute", industry.route) : newRoute
  newRoute = subject ? newRoute.replace(":subjectRoute", subject.route) : newRoute
  return newRoute
}

export const extractRoute = (route, industry, subject) => {
  let newRoute = route
  newRoute = industry ? newRoute.replace(industry.route, ":industryRoute") : newRoute
  newRoute = subject ? newRoute.replace(subject.route, ":subjectRoute") : newRoute
  return newRoute
}
