import { SET_SUBJECT_DONE } from "./actions"
import { createUuid } from "../../utils"

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SUBJECT_DONE: {
      const { industryId, subjectId } = action

      // If the user has already completed this subject, let it be
      if (
        Object.values(state).find(
          s => s.industryId === industryId && s.subjectId === subjectId && s.done
        )
      ) {
        return state
      }

      const id = createUuid()

      return {
        ...state,
        [id]: {
          id,
          industryId,
          subjectId,
          done: true,
        },
      }
    }
    default:
      return state
  }
}
