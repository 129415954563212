/* eslint-disable import/prefer-default-export, react/forbid-prop-types */
import PropTypes from "prop-types"
import buttonTypes from "../components/button/_types"
import buttonIcons from "../components/button/_icons"

const { shape, number, string, bool, arrayOf, object, func } = PropTypes

export const IndustryType = shape({
  _id: string.isRequired,
  tag: string.isRequired,
  isActive: bool.isRequired,
  frontpageVisible: bool.isRequired,
  title: string.isRequired,
  route: string.isRequired,
  tools: arrayOf(string).isRequired,
  bradag_url: string,
})

export const SubjectType = shape({
  _id: string.isRequired,
  route: string.isRequired,
  tag: string.isRequired,
  title: string.isRequired,
})

export const ForbedringspunktType = shape({
  id: string.isRequired,
  industryId: string.isRequired,
  subjectId: string.isRequired,
  dataId: string,
  title: shape({
    text: string,
    backupText: string,
  }).isRequired,
  description: string.isRequired,
  status: number.isRequired,
  priority: number.isRequired,
  deadline: number.isRequired, // JavaScript date, milliseconds since epoch
  responsible: string.isRequired,
  reminderEmails: arrayOf(string.isRequired),
  isChecked: bool.isRequired,
  isUserDefined: bool.isRequired,
})

export const BevaringspunktType = shape({
  title: shape({ text: string.isRequired, backupText: string.isRequired }),
  why: shape({ text: string.isRequired, backupText: string.isRequired }),
  how: shape({ text: string.isRequired, backupText: string.isRequired }),
})

export const ForbedringspunktTextType = shape({
  title: PropTypes.string.isRequired,
  subjectImportance: PropTypes.string.isRequired,
  priorityLabel: PropTypes.string.isRequired,
  statusLabel: PropTypes.string.isRequired,
  deadlineLabel: PropTypes.string.isRequired,
  responsibleLabel: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
  doneLabel: PropTypes.string.isRequired,
  doneDateLabel: PropTypes.string.isRequired,
})

export const ButtonType = {
  id: string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(Object.keys(buttonTypes)),
  icon: PropTypes.oneOf(Object.keys(buttonIcons)),
  url: string,
  onClick: func,
  disabled: bool,
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  reversed: bool,
}

export const ButtonTypeDefaults = {
  id: null,
  type: buttonTypes.internal,
  icon: null,
  url: "",
  onClick: null,
  disabled: false,
  className: "",
  style: null,
  reversed: false,
}
