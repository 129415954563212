import Default from "./default"
import Underline from "./underline"
import Fullscreen from "./fullscreen"
import Subject from "./subject"

export default {
  Default,
  Underline,
  Fullscreen,
  Subject,
}
