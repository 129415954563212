import React from "react"
import { Link as GatsbyLink } from "gatsby"
import icons from "./_icons"
import types from "./_types"
import styles from "./style.module.scss"

const createButton = (id, style, classes, icon, disabled, type, onClick, url, text) => {
  const linkClicked = event => {
    if (onClick && !disabled) {
      event.preventDefault()
      onClick()
    }
  }

  // If external, show html anchor-tag
  if (type === types.external && !disabled) {
    return (
      <a
        id={id}
        href={url}
        onClick={linkClicked}
        className={classes}
        style={style}
        target="_blank"
        rel="noopener external"
      >
        <span>{text}</span>
        <img src={icon ? icons[icon] : icons.external} alt="" />
      </a>
    )
  }
  // If button, there has to be an onClick-function
  if (type === types.button || disabled) {
    return (
      <button id={id} type="button" onClick={linkClicked} className={classes} style={style}>
        <span>{text}</span>
        {icons[icon] ? <img className={styles.main} src={icons[icon]} alt="" /> : null}
        {icons[`${icon}hover`] ? (
          <img className={styles.hover} src={icons[`${icon}hover`]} alt="" />
        ) : null}
      </button>
    )
  }

  // Else, it's internal navigation using a Gatsby link
  return (
    <GatsbyLink id={id} to={url} className={classes} style={style}>
      <span>{text}</span>
      {icons[icon] ? <img src={icons[icon]} alt="" /> : null}
    </GatsbyLink>
  )
}

export default createButton
