import React from "react"
import { Link as GatsbyLink } from "gatsby"
import classNames from "classnames"
import { useLocation } from "@reach/router"
import { ToTheTopButton } from ".."
import styles from "./style.module.scss"
import { IndustryType, SubjectType } from "../../propTypes"
import Breabcrumb from "./breadcrumb"
import Home from "../../images/icons/home.svg"
import ChevronLeft from "../../images/icons/chevron-left.svg"
import AmhLogo from "../../images/icons/amh-logo.svg"

const Header = ({ industry, subject }) => {
  const location = useLocation()

  const amhIcon = <img src={AmhLogo} alt="Startside" />

  const homeIcon = <img src={Home} alt="Home icon" className={styles.navLogoImage} />

  const chevronLeft = <img src={ChevronLeft} alt="Chevron icon" className={styles.navLogoImage} />

  return (
    <>
      <div className="content">
        <div className="desktop-col-12">
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <GatsbyLink
                to={industry ? `/?bransje=${industry.tag}` : "/"}
                className={classNames(styles.link, styles.logo)}
              >
                {amhIcon}
              </GatsbyLink>
              {industry && location.pathname.includes(`/bransje/${industry.route}`) ? (
                <>
                  <span style={{ padding: "0 20px 0 20px" }} className="mobile-display-none">
                    <hr
                      width="1px"
                      size="25"
                      color="#333333"
                      style={{ outline: "none", border: "none" }}
                    />
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink to={`/bransje/${industry.route}`} className={styles.link}>
                      {industry.title}
                    </GatsbyLink>
                  </div>
                  <span style={{ padding: "0 10px 0 10px" }} className="mobile-display-none">
                    {homeIcon}
                  </span>
                </>
              ) : null}
              {industry && location.pathname.includes(`/bransje/${industry.route}/moteleder`) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink to={`/bransje/${industry.route}/moteleder`} className={styles.link}>
                      For deg som skal lede prosessen
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {location.pathname.includes(`/bransje/${industry?.route}/temavelger`) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/temavelger`}
                      className={styles.link}
                    >
                      Velg tema
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {subject &&
              location.pathname.includes(`/bransje/${industry.route}/${subject.route}`) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/temavelger`}
                      className={styles.link}
                    >
                      Velg tema
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {subject &&
              location.pathname.includes(`/bransje/${industry.route}/${subject.route}`) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/${subject.route}`}
                      className={styles.link}
                    >
                      {subject.title}
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {subject &&
              location.pathname.includes(
                `/bransje/${industry.route}/${subject.route}/forbedringspunkter`
              ) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/${subject.route}/forbedringspunkter`}
                      className={styles.link}
                    >
                      Velg og legg til punkter
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {subject &&
              location.pathname.includes(
                `/bransje/${industry.route}/${subject.route}/handlingsplan`
              ) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/${subject.route}/forbedringspunkter`}
                      className={styles.link}
                    >
                      Velg og legg til punkter
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {subject &&
              location.pathname.includes(
                `/bransje/${industry.route}/${subject.route}/handlingsplan`
              ) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/${subject.route}/handlingsplan`}
                      className={styles.link}
                    >
                      Fyll ut handlingsplan
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {industry &&
              location.pathname.includes(`/bransje/${industry.route}/handlingsplan/sammendrag`) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/temavelger`}
                      className={styles.link}
                    >
                      Velg tema
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
              {industry &&
              location.pathname.includes(`/bransje/${industry.route}/handlingsplan/sammendrag`) ? (
                <>
                  <span style={{ padding: "0 10px 0 20px" }} className="mobile-display-none">
                    {chevronLeft}
                  </span>
                  <div className="mobile-display-none">
                    <GatsbyLink
                      to={`/bransje/${industry.route}/handlingsplan/sammendrag`}
                      className={styles.link}
                    >
                      Sammendrag
                    </GatsbyLink>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.headerItemBreadcrumbs,
            "desktop-display-none",
            "tablet-display-none"
          )}
          style={{ marginBottom: "1em" }}
        >
          <Breabcrumb uri={location.pathname} industry={industry} subject={subject} />
        </div>
      </div>

      <ToTheTopButton />
    </>
  )
}

Header.propTypes = {
  industry: IndustryType,
  subject: SubjectType,
}

Header.defaultProps = {
  industry: undefined,
  subject: undefined,
}

export default Header
