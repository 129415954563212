import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link as GatsbyLink } from "gatsby"
import { useLocation } from "@reach/router"
import { Link } from ".."
import { IndustryType, SubjectType } from "../../propTypes"
import BulletYellow from "../../images/icons/bullet-yellow.svg"
import BulletBlack from "../../images/icons/bullet-black.svg"
import Line from "../../images/icons/punktstrek.svg"
import { MenuTextsProptype } from "./propTypes"
import styles from "./default.module.scss"
import { H, Section } from "../../state/levelContext"

const DefaultSecondaryMenu = ({ industry, subject, subjects, className, texts }) => {
  const [isForbedringspktMenuOpen, setForbedringspktMenuOpen] = useState(false)
  const [isVeilederMenuOpen, setVeilederMenuOpen] = useState(false)

  const forbedringspkMenuStyles = classNames(styles.secondaryMenuView, {
    [styles.secondaryMenuViewOpen]: isForbedringspktMenuOpen,
    [styles.secondaryMenuViewClosed]: !isForbedringspktMenuOpen,
  })

  const veilederMenuStyles = classNames(styles.secondaryMenuView, {
    [styles.secondaryMenuViewOpen]: isVeilederMenuOpen,
    [styles.secondaryMenuViewClosed]: !isVeilederMenuOpen,
  })

  const setMenuOpen = (setOpenFunc, value) => {
    const stuffToClose = [setForbedringspktMenuOpen, setVeilederMenuOpen].filter(
      f => f !== setOpenFunc
    )
    stuffToClose.map(setOpen => setOpen(false))

    setOpenFunc(value)
  }
  const location = useLocation()

  const isCurrentPage = url => {
    if (url === "forbedringspunkter" && subject) {
      return location.pathname.includes(subject.route)
    }

    let currentPath = location.pathname
    if (location.pathname.endsWith("/")) {
      currentPath = location.pathname.substring(0, location.pathname.length - 1)
    }

    return currentPath === `/bransje/${industry.route}${url}`
  }

  const menuItems = {
    item1: { text: texts.menuItem1, url: "/bevaringspunkter" },
    item2: { text: texts.menuItem2 },
    item3: { text: texts.menuItem3, url: "/handlingsplan" },
    item4: { text: texts.menuItem4, url: "/handlingsplan/sammendrag" },
    item5: { text: texts.menuItem5, url: "/om" },
  }

  const getActiveLinkStyle = url => {
    return isCurrentPage(url) ? styles.activeLink : null
  }

  const ifSpacePressed = ({ event }) => {
    if (event.keyCode === 0) {
      event.preventDefault()
      setMenuOpen(setForbedringspktMenuOpen, !isForbedringspktMenuOpen)
      event.currentTarget.click()
    }
  }
  return (
    <Section>
      <section className={classNames("primary3-background", styles.sticky, className)}>
        <div className={classNames("content", "flexrow", styles.stickyContent)}>
          <div className={classNames("desktop-flexcol", styles.buttonRow)}>
            <div className="flexrow">
              <div className={styles.title}>
                <img src={BulletYellow} alt="" />
                <span aria-level={1}>{texts.desktopMenuText}</span>
              </div>
              <ul>
                <li className={styles.tab}>
                  {isCurrentPage(menuItems.item1.url) ? <img src={BulletBlack} alt="" /> : null}
                  <Link.Menu
                    url={`/bransje/${industry.route}${menuItems.item1.url}`}
                    text={menuItems.item1.text}
                    small
                    icon="none"
                  />
                </li>
                <li
                  className={classNames(styles.tab, {
                    [styles.openTab]: isForbedringspktMenuOpen,
                  })}
                >
                  {isCurrentPage("forbedringspunkter") ? <img src={BulletBlack} alt="" /> : null}
                  <Link.Menu
                    onClick={() => {
                      setMenuOpen(setForbedringspktMenuOpen, !isForbedringspktMenuOpen)
                    }}
                    text={menuItems.item2.text}
                    small
                    icon={isForbedringspktMenuOpen ? "up" : "down"}
                  />
                  <Section>
                    <div className={classNames(forbedringspkMenuStyles)}>
                      <div className={classNames("flexrow", "content", styles.menuContent)}>
                        {subjects.map(s => {
                          return (
                            <div key={s.tag} className={classNames("desktop-flexcol-2")}>
                              <div className="flexrow">
                                <H className="h6">{s.title}</H>
                              </div>
                              <div className="flexrow">
                                <img src={Line} alt="" className="desktop-flexcol-auto" />
                                <div className={classNames("desktop-flexcol", styles.links)}>
                                  <GatsbyLink
                                    onKeyPress={event => ifSpacePressed({ event })}
                                    to={`/bransje/${industry.route}/${s.route}`}
                                    className={getActiveLinkStyle(`/${s.route}`)}
                                  >
                                    <span>{texts.subMenuItem1}</span>
                                  </GatsbyLink>
                                  <GatsbyLink
                                    onKeyPress={event => ifSpacePressed({ event })}
                                    to={`/bransje/${industry.route}/${s.route}/forbedringspunkter`}
                                    className={getActiveLinkStyle(`/${s.route}/forbedringspunkter`)}
                                  >
                                    <span>{texts.subMenuItem2}</span>
                                  </GatsbyLink>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Section>
                </li>
                <li className={styles.tab}>
                  {isCurrentPage(menuItems.item3.url) ? <img src={BulletBlack} alt="" /> : null}
                  <Link.Menu
                    url={`/bransje/${industry.route}${menuItems.item3.url}`}
                    text={menuItems.item3.text}
                    small
                    icon="none"
                  />
                </li>
                <li className={styles.tab}>
                  {isCurrentPage(menuItems.item4.url) ? <img src={BulletBlack} alt="" /> : null}
                  <Link.Menu
                    url={`/bransje/${industry.route}${menuItems.item4.url}`}
                    text={menuItems.item4.text}
                    small
                    icon="none"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className={classNames("desktop-flexcol-auto", styles.buttonRow)}>
            <div
              className={classNames(styles.tab, {
                [styles.openTab]: isVeilederMenuOpen,
              })}
            >
              {isCurrentPage(menuItems.item5.url) ? <img src={BulletBlack} alt="" /> : null}
              <Link.Menu
                onClick={() => {
                  setMenuOpen(setVeilederMenuOpen, !isVeilederMenuOpen)
                }}
                text={menuItems.item5.text}
                small
                icon={isVeilederMenuOpen ? "up" : "down"}
              />
            </div>
            <Section>
              <div className={classNames(veilederMenuStyles)}>
                <div className={classNames("flexrow", "content", styles.menuContent)}>
                  <div className={classNames("desktop-flexcol-2")}>
                    <div className="flexrow">
                      <H className="h6">{texts.menuItem5}</H>
                    </div>
                    <div className="flexrow">
                      <img src={Line} alt="" className="desktop-flexcol-auto" />
                      <div className={classNames("desktop-flexcol", styles.links)}>
                        <GatsbyLink
                          to={`/bransje/${industry.route}/om`}
                          className={getActiveLinkStyle(`/om`)}
                        >
                          <span>{texts.guideSubMenuItem1}</span>
                        </GatsbyLink>
                        {/* <GatsbyLink
                    to={`/bransje/${industry.route}/om`}
                    className={getActiveLinkStyle(`/om`)}
                  >
                    <span>{texts.guideSubMenuItem2}</span>
                  </GatsbyLink> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
      </section>
    </Section>
  )
}

DefaultSecondaryMenu.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  className: PropTypes.string,
  texts: MenuTextsProptype.isRequired,
}

DefaultSecondaryMenu.defaultProps = {
  subject: null,
  className: null,
}

export default DefaultSecondaryMenu
