import Primary from "./primary"
import Secondary from "./secondary"
import Tertiary from "./tertiary"
import DeletePrompt from "./deletePrompt"
import AddNew from "./addNew"
import Export from "./export"
import Icon from "./icon"

export default {
  Primary,
  Secondary,
  Tertiary,
  DeletePrompt,
  AddNew,
  Export,
  Icon,
}
