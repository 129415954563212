import React from "react"
import classNames from "classnames"
import { Link, ToTheTopButton } from ".."
import LogoAt from "../../images/logo/at_logo.svg"
import VersionNumber from "../versionNumber"
import { GetMenuItems } from "../../constants"
import { IndustryType } from "../../propTypes"
import styles from "./style.module.scss"
import { Section } from "../../state/levelContext"

const Footer = ({ industry }) => {
  let footerItems = GetMenuItems(industry)

  if (industry.showFactsPageOnly) {
    footerItems = footerItems.filter(i => !["Verktøy", "Kunnskap", "Hjelp"].includes(i.title))
  }

  return (
    <footer className={classNames(styles.background)}>
      <Section>
        <div className="content">
          <div className={classNames("desktop-col-12", styles.footerContent)}>
            <a href="https://www.arbeidstilsynet.no/" target="_blank" rel="noopener external">
              <img src={LogoAt} alt="Arbeidstilsynet" className={styles.logo} />
            </a>

            <Section>
              {footerItems.map(category =>
                category.subItems.map(item => (
                  <div key={`footer-link-${item.title}`} style={{ lineHeight: "70px" }}>
                    {item.external ? (
                      <Link.Blank url={item.link} external>
                        {item.title}
                      </Link.Blank>
                    ) : (
                      <Link.Default url={item.link}>{item.title}</Link.Default>
                    )}
                  </div>
                ))
              )}
            </Section>

            <VersionNumber />
          </div>
        </div>
      </Section>
      <ToTheTopButton />
    </footer>
  )
}

Footer.propTypes = {
  industry: IndustryType.isRequired,
}

export default Footer
