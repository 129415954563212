/* eslint-disable react/jsx-curly-newline */
import React, { useState, memo, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Input, DropDown } from "../../../components"
import { FORBEDRINGSPUNKT_PRIORITY as PRIORITIES } from "../../../constants"
import { useDispatch } from "../../../utils"
import { ForbedringspunktType } from "../../../propTypes"
import {
  updateForbedringspunkt,
  setVisitedForbedringspunkt,
} from "../../../state/forbedringspunkter/actions"
import styles from "./style.module.scss"
import { H } from "../../../state/levelContext"
import DateField from "../../../components/textInput/dateField"

// A helper function that adds a click listener to the HTML document,
// and sets 'isVisible'-state to false if click is outside of the component
// 'buttonRef' is a button that opens the component
export const useComponentVisible = (defaultVisible, buttonRef) => {
  const [isVisible, setVisible] = useState(defaultVisible)
  const ref = useRef(null)

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (isVisible && buttonRef.current && buttonRef.current.contains(event.target)) {
        event.stopPropagation()
      }
      setVisible(false)
    }
  }

  useEffect(() => {
    window.document.addEventListener("click", handleClickOutside, true)
    return () => {
      window.document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return { calendarRef: ref, isCalendarVisible: isVisible, setCalendarVisible: setVisible }
}

const constantListToDropDownOptions = constantList => {
  return Object.values(constantList).map(item => {
    return {
      value: item.ID.toString(),
      label: item.TEXT,
    }
  })
}

const constantIdToDropdownOption = (id, list) => {
  const item = Object.values(list).find(i => i.ID === id)
  return {
    value: item.ID.toString(),
    label: item.TEXT,
  }
}

const HandlingsplanSchema = ({ forbedringspunkt, texts }) => {
  const dispatch = useDispatch()

  const onFieldChanged = (field, value) => {
    dispatch(updateForbedringspunkt({ itemId: forbedringspunkt.id, [field]: value }))
    dispatch(setVisitedForbedringspunkt(forbedringspunkt.id))
  }

  return (
    <>
      <div className="content no-padding">
        <div className="desktop-col-7 mobile-col-12">
          <H className={classNames(styles.title)}>{texts.subjects_howLabel}</H>
          <Input.TextArea
            unControlled
            name={texts.subjects_howLabel}
            defaultValue={forbedringspunkt.description}
            onBlur={event => onFieldChanged("description", event.currentTarget.value)}
            placeholder={texts.subjects_howPlaceholder}
            className={styles.textArea}
            rows={5}
            grey
          />
        </div>
        <div className="desktop-col-5 mobile-col-12">
          <div className={styles.endButtonsContainer}>
            <div className={styles.responsibleInput}>
              <H className={styles.title}>{texts.subjects_responsibleLabel}</H>
              <Input.TextField
                unControlled
                name={texts.subjects_responsibleLabel}
                defaultValue={forbedringspunkt.responsible}
                onBlur={event => onFieldChanged("responsible", event.currentTarget.value)}
                placeholder={texts.subjects_responsiblePlaceholder}
                grey
                className={styles.textField}
              />
            </div>
          </div>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropDownItem}>
              <H className={styles.title}>{texts.subjects_priorityLabel}</H>
              <DropDown
                name={texts.subjects_responsibleLabel}
                options={constantListToDropDownOptions(PRIORITIES)}
                onChange={event => onFieldChanged("priority", parseInt(event.value))}
                optionValue={constantIdToDropdownOption(forbedringspunkt.priority, PRIORITIES)}
                ariaLabel="Prioritet"
                className={styles.dropDown}
              />
            </div>
            <div className={styles.dropDownItem}>
              <H className={styles.title}>{texts.subjects_deadlineLabel}</H>
              <DateField
                value={forbedringspunkt.deadline}
                onChange={value => {
                  onFieldChanged("deadline", value)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

HandlingsplanSchema.propTypes = {
  forbedringspunkt: ForbedringspunktType.isRequired,
  texts: PropTypes.shape({
    subjects_howLabel: PropTypes.string.isRequired,
    subjects_howPlaceholder: PropTypes.string.isRequired,
    subjects_priorityLabel: PropTypes.string.isRequired,
    subjects_helpLabel: PropTypes.string.isRequired,
    subjects_deadlineLabel: PropTypes.string.isRequired,
    subjects_responsibleLabel: PropTypes.string.isRequired,
    subjects_responsiblePlaceholder: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(HandlingsplanSchema)
