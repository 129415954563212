import { omit } from "lodash"
import {
  ADD_FORBEDRINGSPUNKT,
  DELETE_FORBEDRINGSPUNKT,
  UPDATE_FORBEDRINGSPUNKT,
  CHECK_USER_FORBEDRINGSPUNKT,
  CHECK_FORBEDRINGSPUNKT,
  SET_DIRTY_FORBEDRINGSPUNKT_TITLE,
  CANCEL_EDIT_FORBEDRINGSPUNKT_TITLE,
  SET_VISITED_FORBEDRINGSPUNKT,
} from "./actions"
import {
  FORBEDRINGSPUNKT_STATUS as STATUS,
  FORBEDRINGSPUNKT_PRIORITY as PRIORITIES,
} from "../../constants"
import { createUuid } from "../../utils"

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_FORBEDRINGSPUNKT: {
      const { industryId, subjectId } = action
      const id = createUuid()

      return {
        ...state,
        [id]: {
          id,
          industryId,
          subjectId,
          dataId: "",
          title: { text: "", backupText: "" },
          description: "",
          status: STATUS.NOT_STARTED.ID,
          priority: PRIORITIES.NONE.ID,
          deadline: -1,
          responsible: "",
          reminderEmails: [],
          isChecked: true,
          isUserDefined: true,
          isVisited: false,
          isNew: true,
        },
      }
    }
    case SET_DIRTY_FORBEDRINGSPUNKT_TITLE: {
      const { forbedringspunktId, isDirty } = action

      // If dirty; take a backup of the real text into backupText, so we can use it later if the user cancels editing
      const titleValue = state[forbedringspunktId].title
      const updatedField = {
        ...titleValue,
        backupText: isDirty ? titleValue.text : titleValue.backupText,
      }

      return {
        ...state,
        [forbedringspunktId]: {
          ...state[forbedringspunktId],
          title: updatedField,
        },
      }
    }
    case CANCEL_EDIT_FORBEDRINGSPUNKT_TITLE: {
      const { forbedringspunktId } = action

      // If cancel is called, the text should be replaced with backupText
      const titleValue = state[forbedringspunktId].title
      const updatedField = {
        ...titleValue,
        text: titleValue.backupText,
        backupText: "",
      }

      return {
        ...state,
        [forbedringspunktId]: {
          ...state[forbedringspunktId],
          title: updatedField,
        },
      }
    }
    case CHECK_FORBEDRINGSPUNKT: {
      const { forbedringspunktId, industryId, subjectId, dataId, title } = action
      const forbedringspunkt = state[forbedringspunktId]

      if (forbedringspunkt) {
        // Delete forbedringspunkter from state when unchecked
        if (forbedringspunkt.isChecked) {
          return omit(state, forbedringspunktId)
        }

        return {
          ...state,
          [forbedringspunktId]: {
            ...forbedringspunkt,
            isChecked: !forbedringspunkt.isChecked,
          },
        }
      }

      const id = createUuid()
      return {
        ...state,
        [id]: {
          id,
          industryId,
          subjectId,
          dataId,
          title: { text: title, backupText: "" },
          description: "",
          status: STATUS.NOT_STARTED.ID,
          priority: PRIORITIES.NONE.ID,
          deadline: -1,
          responsible: "",
          reminderEmails: [],
          isChecked: true,
          isUserDefined: false,
          isVisited: false,
          isNew: false,
        },
      }
    }

    case UPDATE_FORBEDRINGSPUNKT: {
      const {
        forbedringspunktId,
        title,
        description,
        status,
        priority,
        deadline,
        responsible,
        reminderEmails,
      } = action
      const forbedringspunkt = state[forbedringspunktId]

      return {
        ...state,
        [forbedringspunktId]: {
          ...forbedringspunkt,
          title: {
            ...forbedringspunkt.title,
            text: typeof title === "undefined" ? forbedringspunkt.title.text : title,
          },
          description:
            typeof description === "undefined" ? forbedringspunkt.description : description,
          status: status || forbedringspunkt.status,
          priority: priority || forbedringspunkt.priority,
          deadline: deadline || forbedringspunkt.deadline,
          responsible:
            typeof responsible === "undefined" ? forbedringspunkt.responsible : responsible,
          reminderEmails:
            typeof reminderEmails === "undefined"
              ? forbedringspunkt.reminderEmails
              : reminderEmails,
          isNew: false,
        },
      }
    }

    case CHECK_USER_FORBEDRINGSPUNKT: {
      const { forbedringspunktId } = action
      const { isChecked } = state[forbedringspunktId]

      return {
        ...state,
        [forbedringspunktId]: {
          ...state[forbedringspunktId],
          isChecked: !isChecked,
        },
      }
    }

    case DELETE_FORBEDRINGSPUNKT: {
      const { forbedringspunktId } = action
      return omit(state, forbedringspunktId)
    }

    case SET_VISITED_FORBEDRINGSPUNKT: {
      const { forbedringspunktId } = action

      return {
        ...state,
        [forbedringspunktId]: {
          ...state[forbedringspunktId],
          isVisited: true,
        },
      }
    }

    default:
      return state
  }
}
