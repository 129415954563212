export const CREATE_SUBJECT_IMPORTANCE = "CREATE_SUBJECT_IMPORTANCE"
export const SET_SUBJECT_IMPORTANCE = "SET_SUBJECT_IMPORTANCE"

export const createSubjectImportance = (industryId, subjectId, value) => ({
  type: CREATE_SUBJECT_IMPORTANCE,
  industryId,
  subjectId,
  value,
})

export const setSubjectImportance = (id, value) => ({
  type: SET_SUBJECT_IMPORTANCE,
  id,
  value,
})
