export const ADD_BEVARINGSPUNKT = "ADD_BEVARINGSPUNKT"
export const DELETE_BEVARINGSPUNKT = "DELETE_BEVARINGSPUNKT"
export const UPDATE_BEVARINGSPUNKT_TITLE = "UPDATE_BEVARINGSPUNKT_TITLE"
export const SET_DIRTY_BEVARINGSPUNKT_TITLE = "SET_DIRTY_BEVARINGSPUNKT_TITLE"
export const CANCEL_EDIT_BEVARINGSPUNKT_TITLE = "CANCEL_EDIT_BEVARINGSPUNKT_TITLE"
export const CHECK_BEVARINGSPUNKT = "CHECK_BEVARINGSPUNKT"

export const addBevaringspunkt = (industryId, subjectId) => ({
  type: ADD_BEVARINGSPUNKT,
  industryId,
  subjectId,
})

export const deleteBevaringspunkt = bevaringspunktId => ({
  type: DELETE_BEVARINGSPUNKT,
  bevaringspunktId,
})

export const checkBevaringspunkt = bevaringspunktId => ({
  type: CHECK_BEVARINGSPUNKT,
  bevaringspunktId,
})

export const updateBevaringspunktTitle = ({ itemId, title }) => ({
  type: UPDATE_BEVARINGSPUNKT_TITLE,
  bevaringspunktId: itemId,
  title,
})

export const setDirtyBevaringspunktTitle = (bevaringspunktId, isDirty) => ({
  type: SET_DIRTY_BEVARINGSPUNKT_TITLE,
  bevaringspunktId,
  isDirty,
})

export const cancelEditBevaringspunktTitle = bevaringspunktId => ({
  type: CANCEL_EDIT_BEVARINGSPUNKT_TITLE,
  bevaringspunktId,
})
