import RightArrow from "../../images/icons/arrow-right.svg"
import DownArrow from "../../images/icons/arrow-down.svg"
import ExternalIcon from "../../images/icons/external-link.svg"
import PrinterIcon from "../../images/icons/printer.svg"
import EditIcon from "../../images/icons/pencil.svg"
import SaveIcon from "../../images/icons/save.svg"
import CrossIcon from "../../images/icons/cross.svg"
import TrashIcon from "../../images/icons/trash.svg"
import PlusIcon from "../../images/icons/plus.svg"
import FileIcon from "../../images/icons/file.svg"
import MailIcon from "../../images/icons/mail.svg"
import CancelIcon from "../../images/icons/cancel.svg"
import ExcelIcon from "../../images/icons/excel_icon.svg"
import DownloadIcon from "../../images/icons/download.svg"
import PowerPointIcon from "../../images/icons/powerpoint.svg"
import ManusIcon from "../../images/icons/manus.svg"
import VeggprydIcon from "../../images/icons/lagre_veggpryd.svg"
import UtskriftIcon from "../../images/icons/lagre_utskrift.svg"
import TabellIcon from "../../images/icons/lagre_tabell.svg"
import PdfIcon from "../../images/icons/pdf_icon.svg"
import WordIcon from "../../images/icons/word.svg"
import PrintPrimaryIcon from "../../images/icons/print_primary.svg"
import LagreIcon from "../../images/icons/lagre_primary.svg"
import CalendarIcon from "../../images/icons/calendar_primary.svg"
import PrintBlackIcon from "../../images/icons/print_black.svg"
import LagreBlackIcon from "../../images/icons/lagre_black.svg"
import CalendarBlackIcon from "../../images/icons/calendar_black.svg"

const icons = {
  null: null,
  arrowDown: DownArrow,
  arrow: RightArrow,
  print: PrinterIcon,
  plus: PlusIcon,
  cross: CrossIcon,
  pencil: EditIcon,
  save: SaveIcon,
  trash: TrashIcon,
  file: FileIcon,
  mail: MailIcon,
  external: ExternalIcon,
  cancel: CancelIcon,
  excel: ExcelIcon,
  download: DownloadIcon,
  powerpoint: PowerPointIcon,
  manus: ManusIcon,
  veggpryd: VeggprydIcon,
  utskrift: UtskriftIcon,
  tabell: TabellIcon,
  pdf: PdfIcon,
  word: WordIcon,
  printprimary: PrintPrimaryIcon,
  lagreprimary: LagreIcon,
  calendarprimary: CalendarIcon,
  printprimaryhover: PrintBlackIcon,
  lagreprimaryhover: LagreBlackIcon,
  calendarprimaryhover: CalendarBlackIcon,
}

export default icons
