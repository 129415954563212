import React from "react"
import PropTypes from "prop-types"
import { ChapterWrapping, BlockContent } from "."
import ChapterAccordion from "./chapterAccordion"

const Chapter = ({ title, description, content, headerTag: HeaderTag, subchapters, Module }) => {
  return (
    <ChapterWrapping title={title} headerTag={HeaderTag}>
      <BlockContent blocks={content || []} />
      {Module && Module({ title: description })}
      {subchapters && <ChapterAccordion chapters={subchapters} subchapter />}
    </ChapterWrapping>
  )
}

Chapter.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerTag: PropTypes.string,
  subchapters: PropTypes.arrayOf(PropTypes.object),
  Module: PropTypes.func,
}

Chapter.defaultProps = {
  headerTag: "h3",
  description: "",
  subchapters: [],
  Module: null,
}

export default Chapter
