import { UPDATE_DEFAULT_FORBEDRINGSPUNKT_RESPONSIBLE } from "./actions"
import { createUuid } from "../../utils"

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DEFAULT_FORBEDRINGSPUNKT_RESPONSIBLE: {
      const { id, industryId, responsible } = action

      // Update entry if we have one
      if (Object.values(state).find(s => s.id === id)) {
        return {
          ...state,
          [id]: {
            id,
            industryId,
            responsible,
          },
        }
      }

      const newId = createUuid()

      return {
        ...state,
        [newId]: {
          id: newId,
          industryId,
          responsible,
        },
      }
    }
    default:
      return state
  }
}
