/* eslint-disable react/no-danger */
import React, { useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "@reach/router"
import { Button, usePopup } from ".."
import styles from "./style.module.scss"
import useSanity from "../../hooks/useSanity"
import { eventLogger } from "../../utils"
import Link from "../link"
import LogoAt from "../../images/logo/at_logo.svg"
import { setCookieConsentAllowAll, setCookieConsentSave } from "../../state/cookieConsent/actions"

function CookieConsentDialog({ industryTag }) {
  const [CookieConsentPopup, setCookiePopupOpen] = usePopup(false, null, false)

  const { lastChanged } = useSelector(s => s.cookieConsent)
  const location = useLocation()

  // Do not show popup if user has interacted with the cookie consent banner earlier
  useEffect(() => {
    setCookiePopupOpen(lastChanged === 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastChanged])

  useEffect(() => {
    if (CookieConsentPopup) {
      eventLogger.logShowCookies(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CookieConsentPopup])

  const dispatch = useDispatch()

  const acceptAllClicked = () => {
    eventLogger.logAllowAllCookies(location)
    dispatch(setCookieConsentAllowAll(Date.now()))
  }

  const AcceptMandatoryClicked = () => {
    eventLogger.logAllowMandatoryCookies(location)
    dispatch(setCookieConsentSave(Date.now()))
  }

  const { loading, error, data } = useSanity(`*[_type=="cookieConsentLargeModule"][0]`)

  if (loading || error) return null

  return (
    <>
      <CookieConsentPopup>
        <div className={styles.popupWrapper}>
          <div className={styles.headerContainer}>
            <img src={LogoAt} alt="Arbeidstilsynet" className={styles.logo} />
            <span className={styles.logoText}> Arbeidsmiljøhjelpen</span>
          </div>
          <h2 className="normal-font-weight">Personvern</h2>
          <div style={{ marginBottom: 10 }}>{data.text}</div>
          <Link.Default url={industryTag ? `/personvern?bransje=${industryTag}` : "/personvern"}>
            {data.privacyPolicyLinkText}
          </Link.Default>
          <div className={styles.popupButtons}>
            <Button.Secondary
              type="button"
              onClick={AcceptMandatoryClicked}
              className={classNames(styles.button)}
            >
              {data.btnTextRequiredCookies}
            </Button.Secondary>
            <Button.Primary
              type="button"
              onClick={acceptAllClicked}
              className={classNames(styles.button)}
            >
              {data.btnTextApproveAll}
            </Button.Primary>
          </div>
        </div>
      </CookieConsentPopup>
    </>
  )
}

CookieConsentDialog.propTypes = {
  industryTag: PropTypes.string,
}

CookieConsentDialog.defaultProps = {
  industryTag: "",
}

export default CookieConsentDialog
