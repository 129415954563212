import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { BlockContent } from ".."
import { H } from "../../state/levelContext"

function Title({ title, description, content, centerText }) {
  return (
    <div className="content" style={{ paddingBottom: "3rem", paddingTop: "3rem" }}>
      <div className={classNames("desktop-col-7", { "center-text": centerText })}>
        <H className="h1">{title}</H>
        {description !== null ? <p className="h5">{description}</p> : ""}
        {content !== null ? <BlockContent blocks={content || []} /> : null}
      </div>
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any,
  centerText: PropTypes.bool,
}

Title.defaultProps = {
  description: null,
  content: null,
  centerText: false,
}

export default Title
