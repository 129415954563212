import React from "react"
import PropTypes from "prop-types"
import CheckBoxItem from "./checkBoxItem"

const CheckBoxList = ({
  items,
  onItemChecked,
  canEdit,
  editBtnText,
  deleteBtnText,
  saveBtnText,
  setDirtyTitle,
  cancelEditTitle,
  updateItem,
  deleteItem,
}) => {
  return (
    <>
      {items.map(item => {
        if (item.id !== undefined && item.id.startsWith("O") || item.dataId !== undefined && item.dataId !== null && item.dataId.startsWith("O")) {
          return <h4>{item.title}</h4>
        }
        return (
          <CheckBoxItem
            key={`checkbox-${item.id}`}
            item={item}
            onClick={onItemChecked}
            canEdit={canEdit}
            editBtnText={editBtnText}
            deleteBtnText={deleteBtnText}
            saveBtnText={saveBtnText}
            setDirtyTitle={setDirtyTitle}
            cancelEditTitle={cancelEditTitle}
            updateItem={updateItem}
            deleteItem={deleteItem}
          />
        )
      })}
    </>
  )
}

CheckBoxList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
      dataId: PropTypes.string,
    })
  ).isRequired,
  onItemChecked: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  editBtnText: PropTypes.string,
  deleteBtnText: PropTypes.string,
  saveBtnText: PropTypes.string,
  setDirtyTitle: PropTypes.func,
  cancelEditTitle: PropTypes.func,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
}

CheckBoxList.defaultProps = {
  canEdit: false,
  editBtnText: "Endre",
  deleteBtnText: "Slett",
  saveBtnText: "Lagre",
  setDirtyTitle: undefined,
  cancelEditTitle: undefined,
  updateItem: undefined,
  deleteItem: undefined,
}

export default CheckBoxList
