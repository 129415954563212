/* eslint-disable consistent-return */
import React from "react"
import throttle from "lodash/throttle"
import { CONFIG } from "../constants"
import ErrorPage from "../pages/error"

const localStorageAvailable = () => {
  try {
    // eslint-disable-next-line
    typeof window.localStorage
    return true
  } catch (e) {
    return false
  }
}

export const loadState = () => {
  try {
    if (typeof window === "undefined" || !localStorageAvailable()) {
      return {}
    }

    const savedState = JSON.parse(window.localStorage.getItem(CONFIG.LOCAL_STORAGE_KEY))
    return savedState || {}
  } catch (err) {
    return <ErrorPage error={err} />
  }
}

export const saveState = throttle(state => {
  try {
    if (typeof window === "undefined") {
      return
    }
    const states = {
      bevaringspunkter: state.bevaringspunkter,
      subjectDone: state.subjectDone,
      subjectImportance: state.subjectImportance,
      defaultForbedringspunkter: state.defaultForbedringspunkter,
      metaData: {
        version: CONFIG.CURRENT_VERSION,
        lastChanged: Date.now(),
      },
      forbedringspunkter: state.forbedringspunkter,
      cookieConsent: state.cookieConsent,
      languageOption: state.languageOption,
    }

    window.localStorage.setItem(CONFIG.LOCAL_STORAGE_KEY, JSON.stringify(states))
  } catch (err) {
    return <ErrorPage error={err} />
  }
}, CONFIG.THROTTLE_TIME_MS)

export const clearLocalStorage = () => {
  try {
    if (typeof window === "undefined") {
      return
    }

    window.localStorage.clear()
  } catch (err) {
    return <ErrorPage error={err} />
  }
}
