export const getColorStyles = isMenuOpen => {
  return {
    control: (styles, state) => ({
      ...styles,
      outline: state.isFocused ? "2px solid #11c1b0" : "none",
      border: state.isFocused ? "2px solid #11c1b0" : "2px solid #ACACAC",
      boxShadow: "none",
      borderRadius: 0,
      backgroundColor: state.isFocused ? "#FAF5E9" : "none",
      "&:hover": {
        border: "2px solid #11c1b0",
      },
      minHeight: "48px",
      minWidth: "120px",
      lineHeight: "28px",
    }),
    dropdownIndicator: base => ({
      ...base,
      transition: "all .2s ease",
      transform: isMenuOpen ? "rotate(180deg)" : null,
      color: "#000000",
    }),
    placeholder: base => ({
      ...base,
      color: "#000000",
      lineHeight: "6rem",
    }),
    menulist: base => ({
      ...base,
      padding: "0px", // TODO does not work. want to remove whitespace on first and last item
    }),
    menu: base => ({
      ...base,
      border: 0,
      boxShadow: "10px 7px 20px rgba(0, 0, 0, 0.2)",
      marginTop: 0,
      zIndex: 100,
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
  }
}

export default getColorStyles
