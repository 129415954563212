import { combineReducers } from "redux"
import forbedringspunkter from "./forbedringspunkter/reducer"
import bevaringspunkter from "./bevaringspunkter/reducer"
import subjectDone from "./subjectDone/reducer"
import subjectImportance from "./subjectImportance/reducer"
import defaultForbedringspunkter from "./defaultForbedringspunkter/reducer"
import metaData from "./metaData"
import cookieConsent from "./cookieConsent/reducer"
import languageOption from "./selectedLanguageOption/reducer"

export default combineReducers({
  bevaringspunkter,
  subjectDone,
  subjectImportance,
  defaultForbedringspunkter,
  metaData,
  forbedringspunkter,
  cookieConsent,
  languageOption,
})
