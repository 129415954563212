export const ADD_FORBEDRINGSPUNKT = "ADD_FORBEDRINGSPUNKT"
export const UPDATE_FORBEDRINGSPUNKT = "UPDATE_FORBEDRINGSPUNKT"
export const DELETE_FORBEDRINGSPUNKT = "DELETE_FORBEDRINGSPUNKT"
export const CHECK_USER_FORBEDRINGSPUNKT = "CHECK_USER_FORBEDRINGSPUNKT"
export const CHECK_FORBEDRINGSPUNKT = "CHECK_FORBEDRINGSPUNKT"
export const SET_DIRTY_FORBEDRINGSPUNKT_TITLE = "SET_DIRTY_FORBEDRINGSPUNKT_TITLE"
export const CANCEL_EDIT_FORBEDRINGSPUNKT_TITLE = "CANCEL_EDIT_FORBEDRINGSPUNKT_TITLE"
export const SET_VISITED_FORBEDRINGSPUNKT = "SET_VISITED_FORBEDRINGSPUNKT"

export const addForbedringspunkt = (industryId, subjectId) => ({
  type: ADD_FORBEDRINGSPUNKT,
  industryId,
  subjectId,
})

export const deleteItem = forbedringspunktId => ({
  type: DELETE_FORBEDRINGSPUNKT,
  forbedringspunktId,
})

export const setDirtyTitle = (forbedringspunktId, isDirty) => ({
  type: SET_DIRTY_FORBEDRINGSPUNKT_TITLE,
  forbedringspunktId,
  isDirty,
})

export const cancelEditTitle = forbedringspunktId => ({
  type: CANCEL_EDIT_FORBEDRINGSPUNKT_TITLE,
  forbedringspunktId,
})

export const updateForbedringspunkt = ({
  itemId,
  title,
  description,
  status,
  priority,
  deadline,
  responsible,
  reminderEmails,
}) => ({
  type: UPDATE_FORBEDRINGSPUNKT,
  forbedringspunktId: itemId,
  title,
  description,
  status,
  priority,
  deadline,
  responsible,
  reminderEmails,
})

export const checkUserForbedringspunkt = forbedringspunktId => ({
  type: CHECK_USER_FORBEDRINGSPUNKT,
  forbedringspunktId,
})

export const checkForbedringspunkt = ({
  forbedringspunktId,
  industryId,
  subjectId,
  dataId,
  title,
}) => ({
  type: CHECK_FORBEDRINGSPUNKT,
  forbedringspunktId,
  industryId,
  subjectId,
  dataId,
  title,
})

export const setVisitedForbedringspunkt = forbedringspunktId => ({
  type: SET_VISITED_FORBEDRINGSPUNKT,
  forbedringspunktId,
})
