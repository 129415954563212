import { getDateString, getTimeString } from "./index"

const getFileName = (industry, extension = "pdf") => {
  let fileName = `Arbeidsmiljohjelpen_Handlingsplan_${industry}_${getDateString()}-${getTimeString()}`
  if (extension) {
    fileName = fileName.concat(`.${extension}`)
  }
  return fileName
}

const getStringFromBlockContent = (blocks = []) => {
  if (!blocks) return ""

  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children
        .map(child => {
          let line = child.text
          if (!line.endsWith(".")) {
            line = line.concat(". ")
          }
          return line
        })
        .join("")
    })
    .join("\n\n")
}

export { getFileName, getStringFromBlockContent }
