import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Input, Button } from ".."
import styles from "./style.module.scss"
import { useDispatch } from "../../utils"

const CheckBoxItem = ({
  item,
  onClick,
  style,
  canEdit,
  editBtnText,
  deleteBtnText,
  saveBtnText,
  setDirtyTitle,
  cancelEditTitle,
  updateItem,
  deleteItem,
}) => {
  const [isEditing, setIsEditing] = useState(item.isNew)

  // Actions
  const dispatch = useDispatch()

  const onSetDirtyField = isDirty => {
    dispatch(setDirtyTitle(item.id, isDirty))
  }

  const onCancelEditField = () => {
    dispatch(cancelEditTitle(item.id))
  }

  const onTitleChanged = title => {
    dispatch(updateItem({ itemId: item.id, title }))
  }

  const onDeletePromptYes = () => {
    dispatch(deleteItem(item.id))
  }

  const onEdit = () => {
    setIsEditing(true)
  }

  const onTitleFieldBlur = () => {
    onSetDirtyField(false)
    setIsEditing(false)
  }

  return (
    <>
      {isEditing ? (
        <div className={styles.container} style={style}>
          <div style={{ display: "flex", flex: 1 }}>
            <label className={styles.labelContainer}>
              <input type="checkbox" checked={item.isChecked} onChange={() => onClick(item.id)} />
              <span className={styles.checkmark} />
            </label>
            <Input.TextField
              name={`Bruker-forbedringspunkt-${item.id}`}
              autoFocus
              placeholder="Nytt punkt..."
              value={item.title}
              onFocus={() => onSetDirtyField(true)}
              onBlur={() => onTitleFieldBlur()}
              onChange={event => onTitleChanged(event.target.value)}
              onCancelEdit={() => onCancelEditField()}
              style={{ flex: 1 }}
            />
          </div>

          <Button.Tertiary
            icon="save"
            type="button"
            onClick={event => onTitleChanged(event.target.value)}
          >
            {saveBtnText}
          </Button.Tertiary>
        </div>
      ) : (
        <div className={classNames(styles.container, styles.backgroundHover)} style={style}>
          <div style={{ display: "flex", flex: 1 }}>
            <label className={styles.labelContainer}>
              <input type="checkbox" checked={item.isChecked} onChange={() => onClick(item.id)} />
              <span className={styles.checkmark} />
              {item.title}
            </label>
          </div>
          {canEdit && (
            <div style={{ display: "flex" }}>
              <Button.Tertiary
                icon="pencil"
                type="button"
                onClick={() => onEdit()}
                style={{ marginRight: "1rem" }}
              >
                {editBtnText}
              </Button.Tertiary>
              <Button.DeletePrompt
                onPromptYes={() => onDeletePromptYes()}
                deleteText={deleteBtnText}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

CheckBoxItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isNew: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  canEdit: PropTypes.bool,
  editBtnText: PropTypes.string,
  deleteBtnText: PropTypes.string,
  saveBtnText: PropTypes.string,
  setDirtyTitle: PropTypes.func,
  cancelEditTitle: PropTypes.func,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
}

CheckBoxItem.defaultProps = {
  style: null,
  canEdit: false,
  editBtnText: "Endre",
  deleteBtnText: "Slett",
  saveBtnText: "Lagre",
  setDirtyTitle: undefined,
  cancelEditTitle: undefined,
  updateItem: undefined,
  deleteItem: undefined,
}

export default CheckBoxItem
