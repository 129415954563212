import React, { useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { IndustryType, SubjectType } from "../../propTypes"
import { Link } from ".."
import ArrowUpRightIcon from "../../images/icons/arrow-up-right.svg"
import CrossIcon from "../../images/icons/cross.svg"
import styles from "./mobile.module.scss"
import DropDown from "../dropdown"
import { MenuTextsProptype } from "./propTypes"

const getMenuItems = texts => [
  { id: 0, text: texts.menuItem1, url: "/bevaringspunkter" },
  { id: 1, text: texts.menuItem2 },
  { id: 2, text: texts.menuItem3, url: "/handlingsplan" },
  { id: 3, text: texts.menuItem4, url: "/handlingsplan/sammendrag" },
  { id: 4, text: texts.menuItem5, url: "/om" },
]

const MobileSecondaryMenu = ({ industry, subject, subjects, className, texts }) => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const subjectOptions = subjects
    .map(s => {
      return [
        {
          label: `${s.title} - ${texts.subMenuItem1}`,
          value: `/bransje/${industry.route}/${s.route}`,
        },
        {
          label: `${s.title} - ${texts.subMenuItem2}`,
          value: `/bransje/${industry.route}/${s.route}/forbedringspunkter`,
        },
      ]
    })
    .flat()

  const getCurrentOption = () => {
    const uriSubpath = `${subject.route}/forbedringspunkter`

    if (location.pathname.includes(uriSubpath))
      return subjectOptions.find(option => option.value.includes(uriSubpath))
    if (location.pathname.endsWith(subject.route))
      return subjectOptions.find(option => option.value.endsWith(subject.route))
    return null
  }

  return (
    <div className={className}>
      <div className={styles.container}>
        {isOpen ? (
          <div className={styles.popUpMenu}>
            {getMenuItems(texts).map(item => {
              if (item.id === 1) {
                return (
                  <DropDown
                    key={item.id}
                    name="subject"
                    id="subject"
                    options={subjectOptions}
                    optionValue={subject ? getCurrentOption() : null}
                    placeholder={item.text}
                    onChange={event => navigate(event.value)}
                    ariaLabel={item.text}
                  />
                )
              }
              return (
                <Link.Menu
                  key={item.id}
                  className={styles.menuItem}
                  url={`/bransje/${industry.route}${item.url}`}
                  text={item.text}
                  icon="none"
                />
              )
            })}
          </div>
        ) : null}
        <button type="button" onClick={() => setIsOpen(!isOpen)} className={styles.button}>
          <span>{texts.mobileOpenMenuBtnText}</span>
          <img src={isOpen ? CrossIcon : ArrowUpRightIcon} alt={texts.mobileOpenMenuBtnText} />
        </button>
      </div>
    </div>
  )
}

MobileSecondaryMenu.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  className: PropTypes.string,
  texts: MenuTextsProptype.isRequired,
}

MobileSecondaryMenu.defaultProps = {
  subject: null,
  className: null,
}

export default MobileSecondaryMenu
