import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import style from "./style.module.scss"
import { Link } from ".."

const ForwardLink = ({ to, children, className, rotateArrow, secondary }) => {
  return (
    <div className={classNames(style.forwardLink, className)}>
      <Link.Big
        url={to}
        secondary={secondary}
        arrow="right"
        style={{ width: "100%", justifyContent: "space-between" }}
        rotateArrow={rotateArrow}
      >
        {children !== null ? children : "Neste"}
      </Link.Big>
    </div>
  )
}

ForwardLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string,
  className: PropTypes.string,
  rotateArrow: PropTypes.number,
  secondary: PropTypes.bool,
}

ForwardLink.defaultProps = {
  children: null,
  className: null,
  rotateArrow: 0,
  secondary: false,
}

export default ForwardLink
