/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Header from "./header"
import styles from "./style.module.scss"
import { AccordionContext } from "../../context"
import { scrollToElement } from "../../utils"
import { Section } from "../../state/levelContext"

const CustomContent = ({
  id,
  style,
  title,
  defaultOpen,
  children,
  backgroundColor,
  headerBackgroundColor,
  headerBorderColor,
  isVisited,
  openText,
  closeText,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  const contentStyle = classNames(styles.content, {
    [styles.hidden]: !isOpen,
    [styles.yellowBackground]: backgroundColor === "yellow",
    [styles.whiteBackground]: backgroundColor === "white",
    [styles.lightGreyBackground]: backgroundColor === "lightGrey",
    [styles.darkGreyBackground]: backgroundColor === "darkGrey",
    [styles.lightBlueBackground]: backgroundColor === "lightBlue",
  })

  const toggleAccordion = () => {
    scrollToElement(window, isOpen, id)
    setIsOpen(!isOpen)
  }

  return (
    <AccordionContext.Provider
      value={{
        isOpen,
        toggleAccordion,
      }}
    >
      <div className={styles.item} style={style} aria-expanded={isOpen}>
        <Header
          id={id}
          title={title}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          backgroundColor={headerBackgroundColor}
          borderColor={headerBorderColor}
          isVisited={isVisited}
          openText={openText}
          closeText={closeText}
        />
        <Section>
          <div className={contentStyle}>{children}</div>
        </Section>
      </div>
    </AccordionContext.Provider>
  )
}

CustomContent.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.object.isRequired,
  backgroundColor: PropTypes.oneOf(["yellow", "white", "lightGrey", "darkGrey", "lightBlue"]),
  defaultOpen: PropTypes.bool,
  headerBackgroundColor: PropTypes.oneOf(["yellow", "white", "lightGrey", "darkGrey", "lightBlue"]),
  headerBorderColor: PropTypes.oneOf([
    null,
    "yellow",
    "lightYellow",
    "lightGrey",
    "darkGrey",
    "lightBlue",
  ]),
  isVisited: PropTypes.bool,
  openText: PropTypes.string,
  closeText: PropTypes.string,
}

CustomContent.defaultProps = {
  id: null,
  style: null,
  title: "",
  backgroundColor: "lightBlue",
  defaultOpen: false,
  headerBackgroundColor: "lightBlue",
  headerBorderColor: "lightGrey",
  isVisited: true,
  openText: "",
  closeText: "",
}

export default CustomContent
