import { useState } from "react"
import useSanity from "../hooks/useSanity"

export const FORBEDRINGSPUNKT_STATUS = {
  NOT_STARTED: { ID: 1, TEXT: "Ikke startet" },
  IN_PROGRESS: { ID: 2, TEXT: "Igangsatt" },
  DONE: { ID: 3, TEXT: "Utført" },
}

export const FORBEDRINGSPUNKT_PRIORITY = {
  NONE: { ID: -1, TEXT: "Velg prioritet" },
  LOW: { ID: 1, TEXT: "Lav" },
  MEDIUM: { ID: 2, TEXT: "Middels" },
  HIGH: { ID: 3, TEXT: "Høy" },
}

export const CONFIG = {
  DEFAULT_SLIDER_VALUE: 3,
  LOCAL_STORAGE_KEY: "SAVED_STATE",
  CURRENT_VERSION: 1,
  THROTTLE_TIME_MS: 2000,
}

export const PETROLEUM_ID = "petroleum"

const GetOm = industry => {
  const [params] = useState({ id: "links" })
  const { data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...
      }`,
    params
  )
  const om = []
  const industryName = industry !== null ? `?bransje=${industry.tag}` : ""

  if (data !== null) {
    const linkData = data[0]

    if (linkData.om_text !== undefined) {
      om.push({
        title: linkData.om_text,
        link: `${linkData.om_href}${industryName}`,
      })
    }

    if (linkData.faq_text !== undefined) {
      om.push({
        title: linkData.faq_text,
        link: `${linkData.faq_href}${industryName}`,
      })
    }
    if (linkData.personvern_text !== undefined) {
      om.push({
        title: linkData.personvern_text,
        link: `${linkData.personvern_href}${industryName}`,
      })
    }
    if (linkData.webtilgengelighed_text !== undefined) {
      om.push({
        title: linkData.webtilgengelighed_text,
        link: `${linkData.webtilgengelighed_href}${industryName}`,
      })
    }
  }
  return om
}

export const GetMenuItems = industry => {
  const om = GetOm(industry)
  return [
    {
      title: "Om",
      mainCategory: false,
      subItems: om,
    },
  ]
}

export const GetFrontPageOmLinks = () => {
  const om = GetOm(null)
  return {
    title: "Om",
    items: om,
  }
}
