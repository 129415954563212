import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { useComponentVisible } from "../../templates/hjelpen/handlingsplan/_handlingsplanSchema"
import { getDateString, todayOrInTheFuture } from "../../utils"
import { Calendar, Input } from ".."
import CalendarIcon from "../../images/icons/calendar.svg"

const DateField = ({ value, onChange }) => {
  const buttonRef = useRef(null)
  const [isError, setError] = useState("")

  const { calendarRef, isCalendarVisible, setCalendarVisible } = useComponentVisible(
    false,
    buttonRef
  )

  const getDeadlineString = val => {
    return val !== -1 ? getDateString(val) : ""
  }

  const onKeyDown = event => {
    if (event.key === "Escape") {
      setCalendarVisible(false)
    }
  }

  // If calendar is visible, give it focus, so it can listen to key-events
  useEffect(() => {
    if (calendarRef.current && isCalendarVisible) {
      calendarRef.current.focus()
    }
  }, [isCalendarVisible, calendarRef])

  const validateDeadline = text => {
    const regexp = new RegExp("^(0?[1-9]|[12][0-9]|3[01])[\\.](0?[1-9]|1[012])[\\.](\\d{4})$") // dd.MM.yyyy
    const match = text.match(regexp)

    if (match) {
      const year = match[3]
      const month = match[2] - 1 // JavaScript month is 0'indexed
      const day = match[1]
      return new Date(year, month, day, 23, 59, 59)
    }

    return null
  }

  const validateAndSetDeadline = event => {
    const text = event.target.value
    const date = validateDeadline(text)

    if (date) {
      if (todayOrInTheFuture(date)) {
        onChange(date.getTime())
        setError("")
      } else {
        onChange(-1)
        setError("Tidsfristen må være frem i tid")
      }
    } else {
      onChange(-1)
      setError("Bruk formatet dd.mm.åå")
    }
  }

  return (
    <>
      <div className={styles.calendarContainer}>
        <Input.TextField
          name="Neste sjekk"
          unControlled
          defaultValue={getDeadlineString(value)}
          onClick={() => (!isCalendarVisible ? setCalendarVisible(true) : null)}
          onBlur={event => validateAndSetDeadline(event)}
          placeholder="dd.mm.åå"
          grey
        />
        <button
          ref={buttonRef}
          type="button"
          onClick={() => (!isCalendarVisible ? setCalendarVisible(true) : null)}
          className={styles.calendarButton}
        >
          <img src={CalendarIcon} alt="Åpne kalender" />
        </button>
        <div
          ref={calendarRef}
          className={styles.calendarPopup}
          onKeyDown={onKeyDown}
          tabIndex={-1}
          role="button"
        >
          {isCalendarVisible && (
            <Calendar
              name="deadline"
              onChange={event => {
                onChange(event.currentTarget.value.getTime())
                setError("")
              }}
              value={new Date(value)}
              minDate={new Date()}
              onBlur={() => setCalendarVisible(false)}
            />
          )}
        </div>
      </div>
      <div>
        {isError ? (
          <div role="status" className={styles.errorMessage}>
            {isError}
          </div>
        ) : null}
      </div>
    </>
  )
}

DateField.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

DateField.defaultProps = { onChange: () => {} }

export default DateField
