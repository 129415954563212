import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from ".."
import styles from "./style.module.scss"
import { IndustryType } from "../../propTypes"
import { H, Section } from "../../state/levelContext"

function FindIndustryFullWidth({ title, postFix, industries }) {
  const getFullPath = industry => {
    if (postFix) {
      return `/bransje/${industry.route}${postFix}`
    }

    return `/bransje/${industry.route}`
  }

  return (
    <nav
      aria-label={title}
      className={classNames("content", "desktop-col-12", styles.findIndustryFullWidth)}
    >
      <H id="bransje-list-header" className="h3 no-margin-bottom">
        {title}
      </H>
      <div className="break" />
      <Section>
        <ul aria-labelledby="bransje-list-header">
          {industries
            .filter(i => i.isActive !== false)
            .filter(i => !i.showFactsPageOnly)
            .sort((firstIndustry, secondIndustry) => firstIndustry.sort - secondIndustry.sort)
            .map(industry => (
              <li key={industry.route} className={classNames("desktop-col-4")}>
                <Link.Default url={getFullPath(industry)} displayBlock>
                  {industry.title}
                </Link.Default>
              </li>
            ))}
        </ul>
      </Section>
    </nav>
  )
}

FindIndustryFullWidth.propTypes = {
  title: PropTypes.string.isRequired,
  postFix: PropTypes.string,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

FindIndustryFullWidth.defaultProps = {
  postFix: null,
}

export default FindIndustryFullWidth
