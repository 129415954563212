import { CREATE_SUBJECT_IMPORTANCE, SET_SUBJECT_IMPORTANCE } from "./actions"
import { createUuid } from "../../utils"

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_SUBJECT_IMPORTANCE: {
      const { industryId, subjectId, value } = action

      const id = createUuid()

      return {
        ...state,
        [id]: {
          id,
          industryId,
          subjectId,
          importance: value,
        },
      }
    }
    case SET_SUBJECT_IMPORTANCE: {
      const { id, value } = action
      return {
        ...state,
        [id]: {
          ...state[id],
          importance: value,
        },
      }
    }
    default:
      return state
  }
}
