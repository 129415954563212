import classNames from "classnames"
import { ButtonType, ButtonTypeDefaults } from "../../propTypes"
import styles from "./style.module.scss"
import createButton from "./_buttonFactory"
import withDisabledCheck from "../../hooks/withDisabledCheck"

const Icon = ({ id, style, className, icon, disabled, type, onClick, url, children, reversed }) => {
  const classes = classNames(styles.iconButton, className, {
    [styles.disabled]: disabled,
    [styles.reversed]: reversed,
  })

  return createButton(id, style, classes, icon, disabled, type, onClick, url, children)
}

Icon.propTypes = ButtonType
Icon.defaultProps = ButtonTypeDefaults

export default withDisabledCheck(Icon)
