/* eslint-disable react/no-danger */
import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import AlertTriangle from "../../images/icons/alert-triangle.svg"
import { clearLocalStorage } from "../../state/localStorage"
import { Button, usePopup, BlockContent } from ".."
import styles from "./style.module.scss"

function DeleteDataDialog({ small, text }) {
  const [SureAboutDeletePopup, setDeletePopupOpen] = usePopup()

  return (
    <>
      <SureAboutDeletePopup>
        <div className={styles.popupWrapper}>
          <img src={AlertTriangle} alt="Advarsel" />
          <h3>{text.confirmTitle}</h3>
          <p>{text.confirmDescription}</p>
          <div>
            <Button.Secondary
              type="button"
              icon="cross"
              onClick={() => {
                setDeletePopupOpen(false)
              }}
            >
              {text.confirmCancelBtn}
            </Button.Secondary>
            <Button.Secondary
              type="button"
              icon="trash"
              onClick={() => {
                setDeletePopupOpen(false)
                clearLocalStorage()
                window.location.reload()
              }}
            >
              {text.confirmDeleteBtn}
            </Button.Secondary>
          </div>
        </div>
      </SureAboutDeletePopup>
      <div className={classNames(styles.buttonWrapper, small ? styles.border : null)}>
        <div className={classNames(small ? null : "desktop-col-5", styles.column)}>
          <h3 style={{ marginTop: 0 }}>{text.title}</h3>
        </div>
        <div className={classNames(small ? null : "desktop-col-7", styles.column)}>
          <BlockContent style={{ marginTop: 0 }} blocks={text.description || []} />
          <Button.Primary
            type="button"
            icon="trash"
            onClick={() => {
              window.scrollTo(0, 0)
              setDeletePopupOpen(true)
            }}
            style={{ marginTop: "2rem" }}
          >
            {text.deleteBtn}
          </Button.Primary>
        </div>
      </div>
    </>
  )
}

DeleteDataDialog.propTypes = {
  small: PropTypes.bool,
  text: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.object).isRequired,
    deleteBtn: PropTypes.string.isRequired,
    confirmTitle: PropTypes.string.isRequired,
    confirmDescription: PropTypes.string.isRequired,
    confirmDeleteBtn: PropTypes.string.isRequired,
    confirmCancelBtn: PropTypes.string.isRequired,
  }).isRequired,
}

DeleteDataDialog.defaultProps = {
  small: false,
}

export default DeleteDataDialog
