import React from "react"
import { compose } from "redux"
import { withSanity } from "../state/sanityProvider"
import withCodelists from "./withCodelists"
import { Layout } from "../components"

const withLayoutWrapper = WrappedComponent => props => {
  // eslint-disable-next-line react/prop-types
  const { industry, subject, industries, routes } = props
  return (
    <Layout.Default industry={industry} subject={subject} industries={industries} routes={routes}>
      <WrappedComponent {...props} />
    </Layout.Default>
  )
}

const withPageDependencies = compose(withSanity, withCodelists, withLayoutWrapper)

export default withPageDependencies
