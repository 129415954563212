import React, { useRef, useEffect, memo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"

const TextArea = ({
  name,
  placeholder,
  value,
  style,
  rows,
  maxLength,
  autoComplete,
  autoFocus,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  onCancelEdit,
  defaultValue,
  unControlled,
  grey,
  className,
}) => {
  const textArea = useRef(null)

  useEffect(() => {
    if (autoFocus && textArea.current) {
      textArea.current.focus()
    }
  }, [autoFocus])

  const onInputKeyDown = event => {
    if (event.key === "Escape") {
      onCancelEdit()
      textArea.current.blur()
      onBlur(event)
    }
    onKeyDown(event)
  }

  if (unControlled) {
    return (
      <textarea
        name={name}
        aria-label={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={textArea}
        rows={rows}
        style={style}
        className={classNames(styles.inputTextField, className, { [styles.grey]: grey })}
        autoComplete={autoComplete.toString()}
        maxLength={maxLength}
        onKeyDown={onInputKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }

  return (
    <textarea
      name={name}
      aria-label={name}
      placeholder={placeholder}
      ref={textArea}
      value={value}
      rows={rows}
      style={style}
      className={styles.inputTextField}
      autoComplete={autoComplete.toString()}
      maxLength={maxLength}
      onChange={onChange}
      onKeyDown={onInputKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  rows: PropTypes.number,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onCancelEdit: PropTypes.func,
  defaultValue: PropTypes.string,
  unControlled: PropTypes.bool,
  grey: PropTypes.bool,
  className: PropTypes.string,
}

TextArea.defaultProps = {
  placeholder: "Skriv her...",
  value: "",
  style: null,
  rows: 3,
  autoComplete: false,
  autoFocus: false,
  maxLength: 1000,
  defaultValue: null,
  unControlled: false,
  grey: false,
  onChange: () => {},
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onCancelEdit: () => {},
  className: "",
}

export default memo(TextArea)
