/* eslint-disable react/forbid-prop-types, react/prop-types */
import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"
import PropTypes from "prop-types"
import { Link, InfoBox, Button } from "."
import { H } from "../state/levelContext"

const BlockContent = ({ blocks, style }) => {
  // When changing, remember updating src\groq\blockContentGroq.js when referencing other documents
  const serializers = {
    types: {
      block: props => {
        switch (props.node.style) {
          case "h1":
          case "h2":
          case "h3":
          case "h4":
          case "h5":
            return <H className={props.node.style}>{props.children}</H>
          case "blockquote":
            return <blockquote>{props.children}</blockquote>
          default:
            return <p>{props.children}</p>
        }
      },
      infoBox: ({ node }) => {
        const { title, icon, content, smallIcon } = node

        return (
          <InfoBox
            title={title}
            icon={icon?.asset?.url}
            iconAltText={icon?.alt}
            smallIcon={smallIcon}
          >
            <BlockContent blocks={content || []} />
          </InfoBox>
        )
      },
      byLogo: ({ node }) => {
        const { logo, text } = node
        const logoUrl = logo.asset.url
        const logoAlt = logo ? logo.alt : null

        return (
          <div>
            <span className="label" style={{ marginRight: "8px", verticalAlign: "middle" }}>
              {text}
            </span>
            <img
              style={{ maxHeight: "2em", verticalAlign: "middle" }}
              src={logoUrl}
              alt={logoAlt}
            />
          </div>
        )
      },
      attachment: ({ node }) => {
        const { title, file } = node

        return (
          <Button.Primary url={file?.asset?.url} icon="download" type="external">
            {title}
          </Button.Primary>
        )
      },
      formulation: ({ node }) => {
        const { title } = node
        return (
          <ul>
            <li>{title}</li>
          </ul>
        )
      },
      heading: ({ node }) => {
        const { title } = node
        return <h4>{title}</h4>
      },
    },
    marks: {
      link: props => {
        const { mark, children } = props
        if (mark.external || mark.blank) {
          return (
            <Link.Blank url={mark.href} external={mark.external}>
              {children[0]}
            </Link.Blank>
          )
        }
        return <Link.Default url={mark.href}>{children[0]}</Link.Default>
      },
      footnote: props => {
        const { mark } = props
        const { text, href } = mark
        return (
          <sup>
            <a href={href}>{text}</a>
          </sup>
        )
      },
    },
  }
  return <BaseBlockContent style={style} blocks={blocks} serializers={serializers} />
}

BlockContent.propTypes = {
  blocks: PropTypes.any.isRequired,
  style: PropTypes.object,
}

BlockContent.defaultProps = {
  style: null,
}

export default BlockContent
